import './App.css';
import { TerminalProvider } from './context/TerminalContext';
import HomePage from './pages/HomePage';

function App() {
  return (
    <TerminalProvider> 
      <HomePage />
    </TerminalProvider>
  );
}

export default App;
