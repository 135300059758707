import React, { memo } from "react";
import { useAsciiText, smallSlant } from "react-ascii-text";

const AsciiTitle = memo(() => {
  const asciiTextRef = useAsciiText({
    fadeInOnly: true,
    animationLoop: false,
    font: smallSlant,
    text: "Terminal CV",
  });
  
  return <pre ref={asciiTextRef} className='rainbow-text'></pre>;
});

export default AsciiTitle;