import React, { useContext } from 'react';
import Terminal, { ColorMode, TerminalOutput } from 'react-terminal-ui';
import AsciiTitle from '../components/AsciiTitle';
import commandsModule from '../utils/Commands';
import { TerminalContext } from '../context/TerminalContext';
import Prompt from '../components/Prompt';

const HomePage = () => {

  const { terminalLineData, setTerminalLineData } = useContext(TerminalContext);
  const commands = commandsModule(setTerminalLineData);

  // Function to handle terminal input
  const handleInput = (input) => {
    const commandFunction = commands[input];
    const output = commandFunction ? commandFunction() : <span className="text-response">Command not found - Type 'help' to see a list of commands</span>;
    // Include the user's input as part of the terminal output
    setTerminalLineData(prev => [
      ...prev,
      <TerminalOutput><Prompt />{input}</TerminalOutput>,
      <TerminalOutput>{output}</TerminalOutput>
    ]);
  };

  return (
    <div className="container">
      <Terminal
        colorMode={ColorMode.Dark}
        onInput={handleInput}
      >
        <AsciiTitle />
        {terminalLineData.map((line, index) => (
          <React.Fragment key={index}>
            {line}
          </React.Fragment>
        ))}
      </Terminal>
    </div>
  );
};

export default HomePage;
