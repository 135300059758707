import React from "react";
import { TerminalOutput } from "react-terminal-ui";

// Define commands that the terminal can interpret
const commands = (setTerminalLineData) => ({
    'help': () => (
        <span className='terminal-output'>
            <br />
        <span className="rainbow-text">~~~~~ Available Commands ~~~~~ </span><br /><br/>
        <span className='title'>help</span> - Display available commands <br />
        <span className='title'>clear</span> - Clear the terminal <br />
        <span className='title'>about</span> - Information about me <br />
        <span className='title'>contact</span> - Contact details <br />
        <span className='title'>experience</span> - Work experience <br />
        <span className='title'>skills</span> - List skills <br />
        <span className='title'>linkedin</span> - LinkedIn profile <br />
        <span className='title'>github</span> - GitHub profile <br /> <br />
        </span>
    ),
    'clear': () => {
        // To clear the terminal, reset to just the welcome message or keep the title only
        setTerminalLineData([<TerminalOutput>Welcome to My Portfolio Terminal!</TerminalOutput>]);
        return null;
    },
    'about': () => (
        <span className='terminal-output'>
            <br />
        <span className='title'>Name</span>: Kris Poole<br />
        <span className='title'>Role</span>: Software Engineer<br />
        <span className='title'>Description</span>: I am currently a Software Engineer at Boeing, where I work on developing and maintaining Spring Boot RESTful microservices for customer facing Aerospace Projects. <br />
        I have extensive engineering experience since 2014 spanning various domains such as fintech, communications, aerospace and space launch systems. 
        I have a strong background in web development, API development, data extraction, containerization, and distributed systems. <br />
        I enjoy solving complex problems, delivering high-quality software solutions, and collaborating with diverse teams.<br />
        My goal is to leverage my skills and expertise to create innovative and impactful products that contribute to Boeing's mission of connecting, protecting, exploring, and inspiring the world.<br />
        <span className='title'>Skills</span>: Java, Spring Boot, JavaScript, React, Angular, MySQL, Gitlab, CI/CD, AWS, Splunk, Jenkins, Databricks<br /><br />
        </span>
    ),
    'contact': () => (
        <span className='terminal-output'>
            <br />
        <span className='title'>email</span>: kris@krispoole.dev<br />
        <span className='title'>linkedin</span> <a href="https://www.linkedin.com/in/krispoole">https://www.linkedin.com/in/krispoole</a> <br /><br />
        </span>
    ),
    'experience': () => (
        <span className='terminal-output'>
            <br />
        Previous Roles:<br />
        - Lead Software Engineer at <span className='title'>Boeing</span> (2023 - Present)<br />
        - Software Engineer at <span className='title'>Slalom</span> Consulting (2022 - 2023)<br />
        - Lead Pneumatics Engineer at <span className='title'>NASA</span> (2016 - 2021)<br />
        - Production Engineer at <span className='title'>SNF</span> Holding Company (2017 - 2018)<br />
        - Sergeant at United States Marine Corps (<span className='title'>USMC</span>) (2006 - 2014)<br /><br />
        <span className='title'>details --company</span> - to view more information about the job experience ( ex: <span className='title'>details --Boeing</span><br /><br />
        </span>
    ),
    'details --Boeing': () => (
        <span className='terminal-output'>
            <br />
        <span className='title'>Lead Software Engineer at Boeing</span> (2023 - Present)<br />
        I'm actively involved in the migration of legacy APIs to updated long-term support versions of Java, Maven, and Spring Boot, ensuring compatibility and enhancing efficiency. Collaborating with cross-functional teams, I'm optimizing existing Java code and aligning with modern software practices. My work extensively involves ci-cd pipelines and cloud deployment applications to streamline deployment processes, reflecting Boeing's commitment to technological innovation.<br /><br />
        <br /><br />
        </span>
    ),
    'details --Slalom': () => (
        <span className='terminal-output'>
            <br />
        <span className='title'>Lead Software Engineer at Boeing</span> (2022 - 2023)<br />
        I served as the Technical Lead utilizing containerization (Docker and Terraform), ALB, API Gateway, CodeDeploy, and microservices development across various high-profile projects. I also built a new REST API for large-scale financial corporations using Java, Spring Boot, Databricks, and AWS and delivered customer-facing point-of-sale (POS) web app for sales agents for a national communications company using React w/Redux and Adobe Experience Manager.
        <br /><br />
        </span>
    ),
    'details --NASA': () => (
        <span className='terminal-output'>
            <br />
        <span className='title'>Lead Software Engineer at Boeing</span> (2016 - 2021)<br />
        I designed a pneumatics system for Ground Support Equipment that supported Space Launch operations. Successfully delivered innovative time-sensitive solution for Space Launch System (SLS) Mobile Launcher by guiding design and test engineering team through 24-hour operations and managing procurement/installation/testing of critical equipment. I also established testing setup for Orion Spacecraft pneumatics pressure vessels, including testing system design, software design, and installation. Implemented resolutions and scheduling to resolve complex issues by utilizing readily available parts in the NASA inventory to fix design issues before arrival of the launch vehicle.
        <br /><br />
        </span>
    ),
    'details --SNF': () => (
        <span className='terminal-output'>
            <br />
        <span className='title'>Lead Software Engineer at Boeing</span> (2017 - 2018)<br />
        I implemented engineering sustainability and improvements for Production Plant and led installation of new chemical process that lowered production time and reduced costs. Trained and managed plant maintenance personnel. I headed the restructuring of inefficient chemical processes that decreased batch times by 20%.I implemented engineering sustainability and improvements for Production Plant and led installation of new chemical process that lowered production time and reduced costs. Trained and managed plant maintenance personnel. I headed the restructuring of inefficient chemical processes that decreased batch times by 20%.
        <br /><br />
        </span>
    ),
    'details --USMC': () => (
        <span className='terminal-output'>
            <br />
        <span className='title'>Lead Software Engineer at Boeing</span> (2006 - 2014)<br />
        I was appointed to Security Team Leader in Afghanistan for flight operation that transported 43K+ personnel and 300+ staff daily. I also completed 298 missions and served as Operations Administrator for II MEF General at Camp Lejeune, providing 24-hour operations support and Primary Marksmanship Instructor training 18K+ new recruits in 24 months. Reported directly to II MEF General as operations secretary, working 3 consecutive days each week, 12 hours per shift for 6 months.        
        <br /><br />
        </span>
    ),
    'skills': () => (
        <span className='terminal-output'>
        <br />
        Skills:<br />
        - Java<br /> 
        - Spring Boot<br /> 
        - JavaScript<br />
        - React<br />
        - Angular<br /> 
        - MySQL<br /> 
        - Gitlab<br />
        - CI/CD<br /> 
        - AWS<br />
        - Splunk<br />
        - Jenkins<br /> 
        - Databricks<br />
        - Cloud Services
        <br /><br />
        </span>
    ),
    'linkedin': () => (
        <span className='terminal-output'>
            <br />
            LinkedIn: <a href="https://www.linkedin.com/in/krispoole">https://www.linkedin.com/in/krispoole</a>
            <br /><br />
        </span>
    ),
    'github': () => (
        <span className='terminal-output'>
            <br />
            GitHub: <a href="https://github.com/krispoole">https://github.com/krispoole</a>
            <br /><br />
        </span>
    )
});

export default commands;