import React, { createContext, useState } from 'react';
import { TerminalOutput } from 'react-terminal-ui';

export const TerminalContext = createContext();

export const TerminalProvider = ({ children }) => {
  const [terminalLineData, setTerminalLineData] = useState([
    <TerminalOutput>Welcome to Kris Poole's Terminal CV! - Type 'help' to see a list of commands</TerminalOutput>
  ]);

  return (
    <TerminalContext.Provider value={{ terminalLineData, setTerminalLineData }}>
      {children}
    </TerminalContext.Provider>
  );
};
